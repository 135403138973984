import React, { useState, useRef } from 'react';

import { Grid, Typography, Button, makeStyles } from '@material-ui/core';

import { MobileFeatureCard } from './MobileFeatureCard';

const useStyles = makeStyles((theme) => ({
	header: {
		lineHeight: 1.4,
		fontWeight: 700,
		color: '#002D5C',
		paddingBottom: '1rem',
	},
}));

export const MobileFeatures = ({
	featureSection,
	selectedFeature,
	featureHeader,
	featureRef,
	iosLogo,
	androidLogo,
	qbLogo,
	accentColor,
	featureListCta,
	setFeatureModalOpen,
}) => {
	const classes = useStyles();
	const mobileCardRef = useRef(null);
	const [expanded, setExpanded] = useState(0);

	const handleChange = async (index) => {
		await setExpanded(index);
		mobileCardRef?.current?.scrollIntoView({
			behavior: 'smooth',
		});
	};
	return (
		<>
			<Grid
				container
				direction='column'
				justifyContent='center'
				alignItems='center'
				spacing={4}
				style={{ padding: '4rem 0 0 0' }}>
				<Typography variant='h4' className={classes.header}>
					{featureHeader}
				</Typography>
				<div ref={mobileCardRef} />
				{featureSection.map((feature, index) => (
					<MobileFeatureCard
						feature={feature}
						index={index}
						key={index}
						featureRef={featureRef}
						iosLogo={iosLogo}
						androidLogo={androidLogo}
						qbLogo={qbLogo}
						accentColor={accentColor}
						expanded={expanded}
						handleChange={handleChange}
					/>
				))}
			</Grid>
			{!!featureListCta ? (
				<Grid
					container
					direction='row'
					justifyContent='center'
					alignItems='center'>
					<Button
						variant='contained'
						size='medium'
						color='primary'
						onClick={(e) => setFeatureModalOpen(true)}
						style={{
							background: '#002D5C',
							color: 'white',
							margin: '2rem 0',
						}}>
						{featureListCta}
					</Button>
				</Grid>
			) : null}
		</>
	);
};
