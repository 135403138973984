import React from 'react';
import { BgImage } from 'gbimage-bridge';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Container, Grid, makeStyles, Typography } from '@material-ui/core';

import { WaveUpSVG } from '../../WaveSVGs/WaveUpSVG';
import { ReverseWaveDownSVG } from '../../WaveSVGs/ReverseWaveDownSVG';

const useStyles = makeStyles((theme) => ({
	imgCont: {
		// marginTop: '4rem 0',
		// paddingTop: '6rem',
		zIndex: -10000,
		marginTop: '-8rem',
		// [theme.breakpoints.down('xs')]: {
		// 	marginTop: '-4rem',
		// },
	},
	images: {
		padding: '2rem 0 12rem 0',

		[theme.breakpoints.down('sm')]: {
			padding: '4rem 0 6rem 0',
		},
	},
	// images: {
	// 	marginTop: '-6rem',
	// 	padding: '12rem 0 15rem 0',
	// 	[theme.breakpoints.down('xl')]: {
	// 		padding: '10rem 0 12rem 0',
	// 	},
	// 	// [theme.breakpoints.down('lg')]: {
	// 	// 	padding: '22rem 0 12rem 0',
	// 	// },
	// 	[theme.breakpoints.down('md')]: {
	// 		padding: '18rem 0 12rem 0',
	// 	},
	// 	[theme.breakpoints.down('sm')]: {
	// 		padding: '18rem 0 6rem 0',
	// 	},
	// 	[theme.breakpoints.down('xs')]: {
	// 		padding: '30rem 0 6rem 0',
	// 	},
	// },
	stat: {
		color: '#FFF',
		textAlign: 'center',
		lineHeight: 1.7,
		// padding: '2rem 0',
		[theme.breakpoints.down('xs')]: {
			marginBottom: '1rem',
		},
	},
}));

export const CenterWave = ({ background, stats, roi }) => {
	const classes = useStyles();
	return (
		<>
			<BgImage
				className={classes.imgCont}
				image={background.asset?.gatsbyImageData}>
				<ReverseWaveDownSVG fill='#FFF' />
				<Container>
					<Grid
						// style={{ padding: roi && '10rem 0' }}
						className={classes.images}
						container
						spacing={3}
						direction='row'
						justifyContent='space-evenly'
						alignItems='center'>
						{stats.map((stat, index) => (
							<Grid
								item
								sm={6}
								md={roi ? 2 : 3}
								key={index}
								container
								style={{}}
								direction='column'
								alignItems='center'
								justifyContent='center'
								className={classes.stat}>
								<FontAwesomeIcon
									icon={['fad', stat.icon]}
									className={classes.icon}
									size='4x'
								/>
								<Typography
									variant='h6'
									style={{ marginTop: '5px', lineHeight: roi && 1.4 }}>
									{stat.header}
								</Typography>
								<Typography
									variant='subtitle1'
									style={{ color: '#FFF', marginTop: '5px', maxWidth: '80%' }}>
									{stat.body}
								</Typography>
							</Grid>
						))}
					</Grid>
				</Container>
			</BgImage>
			<WaveUpSVG fill='#FFF' />
		</>
	);
};
