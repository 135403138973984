import React from 'react';

import { Container, useMediaQuery } from '@material-ui/core';

import { CenterWave } from './CenterWave';
import { MobileFeatures } from './Features/Mobile/MobileFeatures';
import { BodyFeature } from './Features/BodyFeature';

export const QuickbooksBody = ({
	centerWaveBackground,
	centerWaveDesktop,
	centerWaveMobile,
	featureSection,
	qbLogo,
}) => {
	const md = useMediaQuery('(max-width: 960px)');

	return (
		<>
			<Container>
				<div style={{ marginTop: '-2rem' }}>
					{!md ? (
						featureSection.map((feature, index) => (
							<BodyFeature
								feature={feature}
								index={index}
								key={index}
								accentColor='#002D5C'
								qbLogo={qbLogo}
								qbLink
							/>
						))
					) : (
						<MobileFeatures featureSection={featureSection} qbLogo={qbLogo} />
					)}
				</div>
			</Container>
			<CenterWave background={centerWaveBackground} stats={centerWaveDesktop} />
		</>
	);
};
